import React from 'react'

import Layout from '../components/layout'

const Bot = () => (
  <Layout>
    <div className="bg-black text-white">
      <div className="container mx-auto">
        <div className="px-4 py-10">
          <h1 className="font-light text-4xl">Whoops.io Monitoring Bot</h1>
        </div>
      </div>
    </div>

    <div className="container mx-auto pb-32">
      <div className="px-4 py-4 mb-8 leading-loose">
        <h2 className="pb-4 mt-8">Our Monitoring Bot</h2>

        <p>
          Hello there! You've probably arrived at this page because you spotted
          our user agent in your web server access logs.
        </p>
        <div className="font-mono bg-grey-light p-4
          px-6 text-grey-darkest my-4">
          GET / HTTP/1.1 <br/>
          Host: example.com <br/>
          User-Agent: Whoops.io Monitoring Bot
        </div>
        <p>
          <strong>Whoops.io</strong> is an uptime monitoring service for
          websites and servers.
        </p>
        <p>
          If we're monitoring your website, that's because somebody
          has <a href="https://app.whoops.io/signup">signed up</a> for
          a Whoops.io account and configured a check for your site.
        </p>

        <h2 className="pb-4 mt-8">Reporting Abuse</h2>
        <p>
          If it wasn't you who created a Whoops.io check for your site, you
          might rightly be concerned (or curious) who and why somebody set
          this check up.
        </p>
        <p>
          If this is the case, send us an email
          at <a href="mailto:hello@whoops.io">hello@whoops.io</a> and we'll
          do our best to help.
        </p>
      </div>
    </div>
  </Layout>
)

export default Bot
